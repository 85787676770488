.Input {
  display: block;
  width: 100%;
  min-width: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  color: var(--color-text--shade-1);
  text-overflow: ellipsis;
  line-height: var(--size--x4);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none
}
.Input::-moz-placeholder {
    color: var(--color-text--shade-3);
  }
.Input:-ms-input-placeholder {
    color: var(--color-text--shade-3);
  }
&::-moz-placeholder {
    color: var(--color-text--shade-3);
  }
&:-ms-input-placeholder {
    color: var(--color-text--shade-3);
  }
.Input::placeholder {
    color: var(--color-text--shade-3);
  }
.Input:focus::-moz-placeholder {
    color: transparent;
  }
.Input:focus:-ms-input-placeholder {
    color: transparent;
  }
&:focus::-moz-placeholder {
    color: transparent;
  }
&:focus:-ms-input-placeholder {
    color: transparent;
  }
.Input:focus::placeholder {
    color: transparent;
  }
.Input:focus {
    outline: 0;
  }
.Input:disabled {
    background-color: var(--color-background--shade-3);
  }
