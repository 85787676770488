.Link {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition-property: color, border-width, -webkit-text-decoration-color;
  transition-property: color, border-width, text-decoration-color;
  transition-property: color, border-width, text-decoration-color, -webkit-text-decoration-color;
  transition-duration: var(--transition-time--fast);
  transition-timing-function: var(--transition-function);
  -webkit-text-decoration-color: var(--color-accent--shade-2);
          text-decoration-color: var(--color-accent--shade-2)
}

.Link:hover {
    color: var(--color-accent--shade-1);
    -webkit-text-decoration-color: var(--color-accent--shade-1);
            text-decoration-color: var(--color-accent--shade-1);
  }

.Link.Link--active {
    color: var(--color-accent--shade-2);
    -webkit-text-decoration-color: var(--color-accent--shade-2);
            text-decoration-color: var(--color-accent--shade-2);
  }

.Link:active {
    color: var(--color-accent--shade-3);
    -webkit-text-decoration-color: var(--color-accent--shade-3);
            text-decoration-color: var(--color-accent--shade-3);
  }

.Link--underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
