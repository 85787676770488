:root {
  --Tabs--space: var(--size--x2);
  --Tabs--border: var(--border-size--x2);
}

.Tabs {
  display: flex;
  position: relative;
  overflow: hidden;
}

.Tabs::before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  flex: 1 0 var(--Tabs--space);
  border-bottom: var(--Tabs--border) solid var(--color-text--shade-1);
}

.Tab {
  position: relative;
  transform: translateY(var(--size--x2));
  transform-origin: bottom;
  border: var(--Tabs--border) solid var(--color-text--shade-1);
  border-bottom: none;
  cursor: pointer;
  transition-property: transform, color;
  transition-duration: var(--transition-time--fast);
  transition-timing-function: var(--transition-timing-function);
}

.Tab:not(:last-child) {
  margin-right: var(--Tabs--space);
}

.Tab--active {
  transform: translateY(0);
  color: var(--color-accent--shade-2);
}

.Tab__background {
  transition-property: background-color;
  transition-duration: var(--transition-time--fast);
  transition-timing-function: var(--transition-timing-function);
}

.TabContent {
  border-right: var(--Tabs--border) solid var(--color-text--shade-1);
  border-bottom: var(--Tabs--border) solid var(--color-text--shade-1);
  border-left: var(--Tabs--border) solid var(--color-text--shade-1);
}
