.Alert--accent {
  border-color: var(--color-accent--shade-2);
  color: var(--color-accent--shade-2);
}

.Alert--negative {
  border-color: var(--color-negative--shade-2);
  color: var(--color-negative--shade-2);
}

.Alert--positive {
  border-color: var(--color-positive--shade-2);
  color: var(--color-positive--shade-2);
}

.Alert--fill {
  color: var(--color-white)
}

.Alert--fill.Alert--accent { background-color: var(--color-accent--shade-2); }

.Alert--fill.Alert--negative { background-color: var(--color-negative--shade-2); }

.Alert--fill.Alert--positive { background-color: var(--color-positive--shade-2); }
