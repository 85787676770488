.CheckBox__input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.CheckBox__indicator {
  width: var(--size--x4);
  height: var(--size--x4);
  border: var(--border-size--x2) solid var(--color-text--shade-1);
  transition-property: border-color;
  transition-duration: var(--transition-time--fast);
  transition-timing-function: var(--transition-timing-function)
}

.CheckBox__input:checked + .CheckBox__indicator {
    border-color: var(--color-accent--shade-2)
}

.CheckBox__indicator::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  transform: scale(0);
  background-color: var(--color-accent--shade-2);
  transition-property: transform;
  transition-duration: var(--transition-time--fast);
  transition-timing-function: var(--transition-timing-function)
}

.CheckBox__input:checked + .CheckBox__indicator::before {
    transform: scale(0.5)
}
