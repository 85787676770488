/* stylelint-disable custom-property-empty-line-before */

:root {
  --size--x1: 0.25rem;
  --size--x2: 0.5rem;
  --size--x3: 0.75rem;
  --size--x4: 1rem;
  --size--x5: 1.75rem;
  --size--x6: 1.5rem;
  --size--x8: 2rem;
  --size--x10: 2.5rem;
  --size--x12: 3rem;
  --size--x16: 4rem;

  --border-size--x1: 0.0625rem;
  --border-size--x2: 0.125rem;

  --color-white: #FFFEFC;
  --color-black: #1E1B18;

  --color-dark--shade-1: var(--oc-gray-9);
  --color-dark--shade-2: var(--oc-gray-8);
  --color-dark--shade-3: var(--oc-gray-7);

  --color-light--shade-1: var(--oc-gray-0);
  --color-light--shade-2: var(--oc-gray-1);
  --color-light--shade-3: var(--oc-gray-2);

  --color-accent-1--shade-1: var(--oc-indigo-5);
  --color-accent-1--shade-2: var(--oc-indigo-6);
  --color-accent-1--shade-3: var(--oc-indigo-7);

  --color-accent-2--shade-1: var(--oc-grape-5);
  --color-accent-2--shade-2: var(--oc-grape-6);
  --color-accent-2--shade-3: var(--oc-grape-7);

  --color-positive--shade-1: var(--oc-teal-5);
  --color-positive--shade-2: var(--oc-teal-6);
  --color-positive--shade-3: var(--oc-teal-7);

  --color-negative--shade-1: var(--oc-pink-5);
  --color-negative--shade-2: var(--oc-pink-6);
  --color-negative--shade-3: var(--oc-pink-7);

  --color-highlight: var(--oc-yellow-3);

  --rgb-dark--shade-1: var(--oc-gray-9-rgb);
  --rgb-dark--shade-2: var(--oc-gray-8-rgb);
  --rgb-dark--shade-3: var(--oc-gray-7-rgb);

  --rgb-light--shade-1: var(--oc-gray-0-rgb);
  --rgb-light--shade-2: var(--oc-gray-1-rgb);
  --rgb-light--shade-3: var(--oc-gray-2-rgb);

  --rgb-accent-1--shade-1: var(--oc-indigo-5-rgb);
  --rgb-accent-1--shade-2: var(--oc-indigo-6-rgb);
  --rgb-accent-1--shade-3: var(--oc-indigo-7-rgb);

  --rgb-accent-2--shade-1: var(--oc-grape-5-rgb);
  --rgb-accent-2--shade-2: var(--oc-grape-6-rgb);
  --rgb-accent-2--shade-3: var(--oc-grape-7-rgb);

  --rgb-positive--shade-1: var(--oc-teal-5-rgb);
  --rgb-positive--shade-2: var(--oc-teal-6-rgb);
  --rgb-positive--shade-3: var(--oc-teal-7-rgb);

  --rgb-negative--shade-1: var(--oc-pink-5-rgb);
  --rgb-negative--shade-2: var(--oc-pink-6-rgb);
  --rgb-negative--shade-3: var(--oc-pink-7-rgb);

  --rgb-highlight: var(--oc-yellow-3-rgb);

  --color-overlay: rgba(var(--rgb-dark--shade-1), 0.7);
  --color-elevate: rgba(var(--oc-gray-5-rgb), 0.7);

  --transition-time--fast: 200ms;
  --transition-time--base: 400ms;
  --transition-time--slow: 800ms;
  --transition-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);

  --text-family--heading: 'Merriweather', serif;
  --text-family--content: 'Open Sans', sans-serif;
  --text-family--monospace: 'Courier New', 'Ubuntu Mono', monospace;

  --text-size--x1: 0.875rem;
  --text-size--x2: 1rem;
  --text-size--x3: 1.5rem;
  --text-size--x4: 2.125rem;
  --text-size--x5: 3rem;

  --text-height--x1: 1.4;
  --text-height--x2: 1.4;
  --text-height--x3: 1.25;
  --text-height--x4: 1.25;
  --text-height--x5: 1.2;

  --text-letter-spacing--x1: 0.01785714286rem;
  --text-letter-spacing--x2: 0.03125rem;
  --text-letter-spacing--x3: 0;
  --text-letter-spacing--x4: 0.007352941176rem;
  --text-letter-spacing--x5: 0;

  --text-size--body: var(--text-size--x2);
  --text-height--body: var(--text-height--x2);
  --text-letter-spacing--body: var(--text-letter-spacing--x2);

  --text-weight--weak: 400;
  --text-weight--strong: 700;
}

.Theme--day {
  --color-background--shade-1: var(--color-white);
  --color-background--shade-2: var(--color-light--shade-1);
  --color-background--shade-3: var(--color-light--shade-2);

  --color-text--shade-1: var(--color-dark--shade-1);
  --color-text--shade-2: var(--color-dark--shade-2);
  --color-text--shade-3: var(--color-dark--shade-3);

  --rgb-background--shade-1: var(--rgb-white);
  --rgb-background--shade-2: var(--rgb-light--shade-1);
  --rgb-background--shade-3: var(--rgb-light--shade-2);

  --rgb-text--shade-1: var(--rgb-dark--shade-1);
  --rgb-text--shade-2: var(--rgb-dark--shade-2);
  --rgb-text--shade-3: var(--rgb-dark--shade-3);
}

.Theme--night {
  --color-background--shade-1: var(--color-dark--shade-1);
  --color-background--shade-2: var(--color-dark--shade-2);
  --color-background--shade-3: var(--color-dark--shade-3);

  --color-text--shade-1: var(--color-light--shade-1);
  --color-text--shade-2: var(--color-light--shade-2);
  --color-text--shade-3: var(--color-light--shade-3);

  --rgb-background--shade-1: var(--rgb-dark--shade-1);
  --rgb-background--shade-2: var(--rgb-dark--shade-2);
  --rgb-background--shade-3: var(--rgb-dark--shade-3);

  --rgb-text--shade-1: var(--rgb-light--shade-1);
  --rgb-text--shade-2: var(--rgb-light--shade-2);
  --rgb-text--shade-3: var(--rgb-light--shade-3);
}

html.Theme--day {
  --color-accent--shade-1: var(--color-accent-1--shade-1);
  --color-accent--shade-2: var(--color-accent-1--shade-2);
  --color-accent--shade-3: var(--color-accent-1--shade-3);

  --rgb-accent--shade-1: var(--rgb-accent-1--shade-1);
  --rgb-accent--shade-2: var(--rgb-accent-1--shade-2);
  --rgb-accent--shade-3: var(--rgb-accent-1--shade-3);
}

html.Theme--night {
  --color-accent--shade-1: var(--color-accent-2--shade-1);
  --color-accent--shade-2: var(--color-accent-2--shade-2);
  --color-accent--shade-3: var(--color-accent-2--shade-3);

  --rgb-accent--shade-1: var(--rgb-accent-2--shade-1);
  --rgb-accent--shade-2: var(--rgb-accent-2--shade-2);
  --rgb-accent--shade-3: var(--rgb-accent-2--shade-3);
}
