.TextArea {
  display: block;
  width: 100%;
  min-width: 0;
  height: 100%;
  border: 0;
  border-radius: 0;
  background-color: var(--color-background--shade-1);
  color: var(--color-text--shade-1);
  resize: none;
  transition-property: border-color;
  transition-duration: var(--transition-time--base);
  transition-timing-function: var(--transition-timing-function);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none
}
.TextArea::-moz-placeholder {
    color: var(--color-text--shade-3);
  }
.TextArea:-ms-input-placeholder {
    color: var(--color-text--shade-3);
  }
&::-moz-placeholder {
    color: var(--color-text--shade-3);
  }
&:-ms-input-placeholder {
    color: var(--color-text--shade-3);
  }
.TextArea::placeholder {
    color: var(--color-text--shade-3);
  }
.TextArea:focus::-moz-placeholder {
    color: transparent;
  }
.TextArea:focus:-ms-input-placeholder {
    color: transparent;
  }
&:focus::-moz-placeholder {
    color: transparent;
  }
&:focus:-ms-input-placeholder {
    color: transparent;
  }
.TextArea:focus::placeholder {
    color: transparent;
  }
.TextArea:focus {
    outline: 0;
  }
.TextArea:disabled {
    background-color: var(--color-background--shade-3);
  }
