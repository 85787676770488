.ThemeSwitcher {
  border-radius: var(--size--x16);
  border-color: var(--color-text--shade-1);
  transition-property: border-color;
  transition-duration: var(--transition-time--fast);
  transition-timing-function: var(--transition-timing-function)
}

.ThemeSwitcher:hover { border-color: var(--color-accent--shade-1); }

.ThemeSwitcher:active { border-color: var(--color-accent--shade-3); }

.ThemeSwitcher__input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.ThemeSwitcher__toggle {
  background-color: var(--color-text--shade-1);
  color: transparent;
  transition-property: background-color;
  transition-duration: var(--transition-time--fast);
  transition-timing-function: var(--transition-timing-function)
}

.ThemeSwitcher:hover .ThemeSwitcher__toggle { background-color: var(--color-accent--shade-1)
}

.ThemeSwitcher:active .ThemeSwitcher__toggle { background-color: var(--color-accent--shade-3)
}
