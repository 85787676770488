.Table {
  width: 100%;
  border-spacing: 0;
  text-align: left;
}

.Table__row {
  transition-property: background-color. color;
  transition-duration: var(--transition-time--base);
  transition-timing-function: var(--transition-timing-function);
}

.Table__row--clickable:hover {
    background-color: var(--color-accent--shade-1);
    color: var(--color-light--shade-1);
  }

.Table__row--clickable.Table__row--active,
  .Table__row--clickable:active {
    background-color: var(--color-accent--shade-3);
    color: var(--color-light--shade-1);
  }

.Table__header-cell {
  border-bottom: var(--border-size--x2) solid var(--color-text--shade-1);
}

.Table__cell {
  border-bottom: var(--border-size--x1) solid var(--color-text--shade-1);
}
