.SelectInputLabels {
  border: var(--border-size--x2) solid var(--color-text--shade-1);
  background-color: var(--color-background--shade-1);
}

.SelectInputLabels__label {
  border: var(--border-size--x2) solid var(--color-text--shade-1);
  cursor: pointer;
  transition-property: border-color;
  transition-duration: var(--transition-time--base);
  transition-timing-function: var(--transition-timing-function)
}

.SelectInputLabels__label:hover {
    border-color: var(--color-accent--shade-1);
    color: var(--color-accent--shade-1);
  }

.SelectInputLabels__label:focus-within {
    border-color: var(--color-accent--shade-3);
  }

.SelectInputLabels__label[checked] {
    color: var(--color-text-accent--shade-2);
  }

.SelectInputLabels__label[disabled] {
    border-color: var(--color-text--shade-3);
    background-color: var(--color-background--shade-3);
    color: var(--color-text--shade-3);
    cursor: default;
  }

.SelectInputLabels .SelectInputLabels__label {
  border: 0
}

.SelectInputLabels .SelectInputLabels__label:not(:last-child) {
    border-bottom: var(--border-size--x2) solid var(--color-text--shade-1);
  }

.SelectInputLabels__label-text {
  transition-property: color;
  transition-duration: var(--transition-time--base);
  transition-timing-function: var(--transition-timing-function);
}
