.List {
  padding: 0;
  list-style: none;
}

.ListItem:not(:last-child)::after {
  content: attr(data-separator)
}

.List--x1 > .ListItem:not(:last-child)::after { margin-left: var(--size--x1)
}

.List--x2 > .ListItem:not(:last-child)::after { margin-left: var(--size--x2)
}

.List--x3 > .ListItem:not(:last-child)::after { margin-left: var(--size--x3)
}

.List--x4 > .ListItem:not(:last-child)::after { margin-left: var(--size--x4)
}

.List--x6 > .ListItem:not(:last-child)::after { margin-left: var(--size--x6)
}

.List--x8 > .ListItem:not(:last-child)::after { margin-left: var(--size--x8)
}

.List--x10 > .ListItem:not(:last-child)::after { margin-left: var(--size--x10)
}

.List--x12 > .ListItem:not(:last-child)::after { margin-left: var(--size--x12)
}

.List--x16 > .ListItem:not(:last-child)::after { margin-left: var(--size--x16)
}
