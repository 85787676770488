.Button {
  padding: var(--size--x2) var(--size--x3);
  border-width: var(--border-size--x2);
  border-style: solid;
  border-color: currentColor;
  background-color: transparent;
  color: var(--color-text--shade-1);
  font-size: var(--text-size--x1);
  font-weight: var(--text-weight--strong);
  text-transform: uppercase;
  line-height: var(--text-height--x1);
  transition-property: border-color, background-color, color, opacity;
  transition-duration: var(--transition-time--fast);
  transition-timing-function: var(--transition-timing-function)
}

.Button:hover {
    cursor: pointer;
  }

.Button:focus {
    outline: 0;
  }

.Button[disabled],
  .Button[disabled]:hover,
  .Button[disabled]:active,
  .Button[disabled].Button--active {
    opacity: 0.75;
    color: var(--color-text--shade-3);
    cursor: default;
  }

.Button::before,
  .Button::after {
    content: '';
    flex: 1 0 auto;
  }

.Button--accent:hover { color: var(--color-accent--shade-1); }

.Button--accent.Button:active,
  .Button--accent.Button--active {
    color: var(--color-accent--shade-3);
  }

.Button--positive:hover { color: var(--color-positive--shade-1); }

.Button--positive.Button:active,
  .Button--positive.Button--active {
    color: var(--color-positive--shade-3);
  }

.Button--negative:hover { color: var(--color-negative--shade-1); }

.Button--negative.Button:active,
  .Button--negative.Button--active {
    color: var(--color-negative--shade-3);
  }

.Button--fill {
  border-color: var(--color-text--shade-1);
  background-color: var(--color-text--shade-1);
  color: var(--color-background--shade-1)
}

.Button--fill:hover.Button--accent {
      border-color: var(--color-accent--shade-1);
      background-color: var(--color-accent--shade-1);
      color: var(--color-light--shade-1);
    }

.Button--fill:hover.Button--positive {
      border-color: var(--color-positive--shade-1);
      background-color: var(--color-positive--shade-1);
      color: var(--color-light--shade-1);
    }

.Button--fill:hover.Button--negative {
      border-color: var(--color-negative--shade-1);
      background-color: var(--color-negative--shade-1);
      color: var(--color-light--shade-1);
    }

.Button--fill:active.Button--accent, .Button--fill.Button--active.Button--accent {
      border-color: var(--color-accent--shade-3);
      background-color: var(--color-accent--shade-3);
      color: var(--color-light--shade-1);
    }

.Button--fill:active.Button--positive, .Button--fill.Button--active.Button--positive {
      border-color: var(--color-positive--shade-3);
      background-color: var(--color-positive--shade-3);
      color: var(--color-light--shade-1);
    }

.Button--fill:active.Button--negative, .Button--fill.Button--active.Button--negative {
      border-color: var(--color-negative--shade-3);
      background-color: var(--color-negative--shade-3);
      color: var(--color-light--shade-1);
    }

.Button--fill[disabled],
  .Button--fill[disabled]:hover,
  .Button--fill[disabled]:active,
  .Button--fill[disabled].Button--active {
    border-color: var(--color-text--shade-3);
    background-color: var(--color-text--shade-3);
    color: var(--color-background--shade-3);
  }

.Buttons--joined > .Button:not(:last-child) {
  border-right-width: 0
}

.Buttons--joined > .Button:not(:last-child).Button--active,
  .Buttons--joined > .Button:not(:last-child):not([disabled]):hover,
  .Buttons--joined > .Button:not(:last-child):not([disabled]):active {
    border-right-width: var(--border-size--x2)
  }

.Buttons--joined > .Button:not(:last-child).Button--active + .Button, .Buttons--joined > .Button:not(:last-child):not([disabled]):hover + .Button, .Buttons--joined > .Button:not(:last-child):not([disabled]):active + .Button {
      border-left-width: 0;
    }
